/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import createAuth0Client from '@auth0/auth0-spa-js';
import mbpLogger from 'mbp-logger';
import { channel } from 'redux-saga';
import mbpUtil from 'mbp-api-util';
import getAuth0Config from './auth-config';
import {
    customCallbackURL,
    deffer,
    getAccessTokenInSession,
    getTokenLocalStorage,
    parseUserInfo,
} from './helpers';
import {
    logUserLoggedInFailed,
    logGuestSession,
    logHydraidUserSession,
    logUserLoggedOut,
} from '../../Auth-Actions';
import { getGuestUserAccessToken } from './guest';

export const authChannel = channel();
let brandName;
if ((typeof window !== 'undefined') && window.location && window.location.hostname) brandName = window.location.hostname.split('.')[1]; //  state is not available with brand value so couldn't use getbrandName hook here.
const authConfig = getAuth0Config(brandName);

const auth0Client = () => {
    const isSSR = typeof window === 'undefined';
    const waitAuthReady = deffer();

    let authInstance = null;
    let isReady = false;

    async function createClient(clientId, timeoutInSeconds) {
        if (isSSR) {
            return;
        }

        if (authInstance || !clientId) {
            return;
        }

        try {
            authInstance = await createAuth0Client({
                domain: authConfig.domain,
                client_id: clientId,
                redirect_uri: `${authConfig.redirectURL}`,
                audience: authConfig.audience,
                useRefreshTokens: true,
                cacheLocation: 'localstorage',
                useCookiesForTransactions: true,
                appSource: 'PWA',
                authorizeTimeoutInSeconds: timeoutInSeconds || 20,
            });
        } catch (ex) {
            mbpLogger.logError({
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
                fileName: 'auth.js',
                function: 'createClient',
                jsError: ex,
                message: 'failed to create auth0 client, this might be due to browser version or timeout',
            });
        }
    }

    function getInstance() {
        return authInstance;
    }

    const setAuthReady = () => {
        isReady = true;

        waitAuthReady.resolve();
    };

    const getUserInfo = async () => {
        const user = await authInstance.getUser();

        const userInfo = parseUserInfo(
            user,
            ['firstName', 'lastName', 'contactId', 'email', 'XAtt3', 'XAtt4', 'XAtt5', 'loginsCount', 'loyaltyStatus', 'sub', 'connection'],
        );

        return userInfo;
    };

    const logout = async () => {
        if (typeof window !== 'undefined') {
            if (authInstance) {
                await authInstance.logout({
                    returnTo: window.location.origin,
                });
            } else {
                window.location.href = '/';
            }
        }
    };

    return {
        isReady,
        waitAuthReady,
        createClient,
        getInstance,
        setAuthReady,
        getUserInfo,
        logout,
    };
};

export const auth0 = auth0Client();

export const loginWithInterstital = async (params = {}) => {
    const authClient = auth0.getInstance();

    const appState = {
        routeBack: params.routeBack,
    };

    const callbackURL = customCallbackURL(authConfig.redirectURL, {
        source: authConfig.appSource,
        ...params,
    });

    authClient.loginWithRedirect({
        audience: authConfig.audience,
        appState,
        callbackURL,
        ...params,
    });
};

export const loginWithRedirect = async (options = {}, params = {}, brandCode) => {
    try {
        const authClient = auth0.getInstance();

        const auth0Options = options;
        const appState = {};

        const callbackURL = customCallbackURL(authConfig.redirectURL, {
            source: authConfig.appSource,
            ...params,
        });

        auth0Options['ext-brand'] = brandCode;

        if (params?.routeBack) {
            appState.routeBack = params.routeBack;
        }

        if (params?.register === 'Y') auth0Options['screen_hint'] = 'signup';

        await authClient.loginWithRedirect({
            audience: authConfig.audience,
            appState,
            callbackURL,
            ...auth0Options,
        });
    } catch (ex) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            fileName: 'auth.js',
            function: 'loginWithRedirect',
            jsError: ex,
            message: 'failed to call login with redirect',
        });
    }
};

const checkRegisteredAccessToken = async (accessTokenInSession) => {
    let token = '';

    try {
        const authClient = auth0.getInstance();

        if (authClient) {
            token = await authClient.getTokenSilently({
                audience: authConfig.audience,
            });

            if (accessTokenInSession !== token) {
                const userInfo = await auth0.getUserInfo();

                authChannel.put(logHydraidUserSession({
                    user: userInfo,
                    isAuthenticated: true,
                    accessToken: token,
                }));
            }
        }
    } catch (ex) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            fileName: 'auth.js',
            function: 'checkRegisteredAccessToken',
            jsError: ex,
            message: 'failed to silent auth',
        });

        authChannel.put(logUserLoggedInFailed());

        authChannel.put(logUserLoggedOut());

        auth0.logout();
    }

    return token;
};

const checkGuestAccessToken = async (accessTokenInSession) => {
    const token = await getGuestUserAccessToken();

    if (accessTokenInSession !== token) {
        let userInfo = getTokenLocalStorage('userInfo') || null;
        userInfo = JSON.parse(userInfo);

        authChannel.put(logGuestSession({
            user: userInfo?.profile || {},
            isAuthenticated: false,
            accessToken: token,
        }));
    }

    return token;
};

export const getAccessTokenSafely = async () => {
    let accessToken = '';

    if (!auth0.isReady) {
        await auth0.waitAuthReady;
    }

    const authClient = auth0.getInstance();

    if (!authClient) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'auth.js',
            message: `authClient was marked ready and is still ${authClient}`,
            function: 'getAccessTokenSafely',
        });

        // allow the function to finish to get the token in localstorage
    }

    try {
        // if no auth client, dont get registered and throw in the guest
        const isAuthenticated = authClient ? await authClient.isAuthenticated() : false;
        const accessTokenInSession = getAccessTokenInSession();
        const jwtTest = mbpUtil.testJWT(accessTokenInSession);

        if ((jwtTest?.isValid && !jwtTest?.isGuest) || isAuthenticated) { // registered user
            accessToken = await checkRegisteredAccessToken(accessTokenInSession);
        } else if (jwtTest?.isValid && jwtTest?.isGuest) { // guest user
            accessToken = await checkGuestAccessToken(accessTokenInSession);
        } else {
            accessToken = await checkGuestAccessToken(accessTokenInSession);
        }
    } catch (err) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'auth.js',
            function: 'getAccessTokenSafely',
            message: `Error checking the jwt token ${err.message}`,
            error: err,
        });
    }

    return accessToken;
};
